import React from "react"

import PropTypes from "prop-types"

import "./ImageContainer.scss"

const ImageContainer = ({ src }) => {
  return (
    <section>
      <div
        className="image-container"
        style={{ backgroundImage: `url(${src})` }}
      />
    </section>
  )
}

ImageContainer.propTypes = {
  src: PropTypes.string.isRequired,
}

export default ImageContainer
