import React from "react"

import { graphql } from "gatsby"

import PropTypes from "prop-types"

import ProductList from "../components/products/ProductList"
import ImageContainer from "../components/sections/ImageContainer"
import SEO from "../components/seo"

const ProductCollection = ({ data }) => {
  const { collection } = data.saleor

  return (
    <>
      <SEO
        title={collection.seoTitle}
        description={collection.seoDescription}
        path={`/collections/${collection.slug}/`}
      />
      <div
        style={{
          fontSize: 41,
          fontWeight: "bold",
          marginBottom: 31,
          marginTop: 21,
          textAlign: "center",
          marginRight: 25,
          marginLeft: 25,
        }}
      >
        {collection.name}
      </div>
      {collection?.backgroundImage?.url && (
        <ImageContainer src={collection?.backgroundImage?.url} />
      )}
      <ProductList hasHeader={false} products={collection.products} />
    </>
  )
}

export const pageQuery = graphql`
  query CollectionBySlug($slug: String!) {
    saleor {
      collection(slug: $slug) {
        id
        slug
        seoTitle
        seoDescription
        name
        descriptionJson
        backgroundImage {
          alt
          url
        }
        products(first: 100, filter: { stockAvailability: IN_STOCK }) {
          edges {
            node {
              id
              name
              slug
              visibleInListings
              isAvailable
              isAvailableForPurchase
              pricing {
                discount {
                  gross {
                    amount
                    currency
                  }
                }
              }
              category {
                name
              }
              variants {
                name
                id
                pricing {
                  price {
                    gross {
                      amount
                      currency
                    }
                  }
                }
              }
              thumbnail {
                url
                alt
              }
            }
          }
        }
      }
    }
  }
`

ProductCollection.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProductCollection
